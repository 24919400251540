<template>
  <div>
    <b-table-simple
      bordered
      responsive
    >
      <b-tr>
        <b-td>Name</b-td>
        <b-td />
      </b-tr>
      <b-tr>
        <b-td>Institute</b-td>
        <b-td />
      </b-tr>
      <b-tr>
        <b-td>Roll No.</b-td>
        <b-td />
      </b-tr>
      <b-tr>
        <b-td>Phone</b-td>
        <b-td />
      </b-tr>
      <b-tr>
        <b-td>Email</b-td>
        <b-td />
      </b-tr>
      <b-tr>
        <b-td>Linkedin Profile</b-td>
        <b-td />
      </b-tr>
      <b-tr>
        <b-td>Facebook Profile</b-td>
        <b-td />
      </b-tr>
    </b-table-simple>
  </div>
</template>
<script>

import { BTableSimple, BTd, BTr } from 'bootstrap-vue'

import gql from 'graphql-tag'

export default {
  components: {
    BTableSimple,
    BTr,
    BTd,
  },
  data() {
    return {
      program: {},
    }
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_assignmenttable(where: {programs_operationstable: {program_id: {_eq: ${this.$route.params.id}}}}) {
            id
            title
            type
            programs_operationstable {
              title
              operations_type
            }
            status
            programs_studentassignmenttables {
              student_id
              assignment_id
            }
            programs_startupassignmenttables {
              participant_id
              assignment_id
            }
          }
        }`
      },
      update: data => data.programs_assignmenttable,
    },
  },
}
</script>
